<template>
  <g>
    <linearGradient id="inox-gradient" x1="0%" y1="0%" x2="150%" y2="150%" gradientUnits="userSpaceOnUse">
      <stop offset="0%" stop-color="#bcb9b6"/>
      <stop offset="33%" stop-color="#969593"/>
      <stop offset="50%" stop-color="#bcb8b4"/>
      <stop offset="66%" stop-color="#e1e0e0"/>
      <stop offset="100%" stop-color="#e3e3e2"/>
    </linearGradient>

    <rect id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="inoxX"
          :y="inox2Y"
          :width="inoxWidth"
          height="2.12"/>
    <polygon
      id="inox"
      v-if="showInox"
      data-name="inox"
      class="inox"
      :points="`
					${inox1X} ${inox1Y},
					${inox1X} ${inox1Y2},
					${inoxX} ${inox1Y3},
					${inoxX} ${inox1Y4},
					${inox1X} ${inox1Y}
				`"/>
    <!--
      x118.8 y153 - правая верхняя
      x118.8 y155 - правая нижняя
      x22 y181.3 - левая нижняя
      x22 y179.3 - левая верхняя
      x118.8 y153 - правая верхняя ещё раз, если надо замкнуть полигон
    -->
    <rect id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="inoxX"
          :y="inox3Y"
          :width="inoxWidth"
          height="2.13"/>
    <polygon
      v-if="showInox"
      id="inox-4"
      data-name="inox"
      class="inox"
      :points="`
					${inox1X} ${inox4Y},
					${inoxX} ${inox4Y2},
					${inoxX} ${inox4Y3},
					${inox1X} ${inox4Y4}
				`"/>
    <rect id="inox-5"
          v-if="showInox"
          data-name="inox"
          class="inox"
          :x="inoxX"
          :y="inox5Y"
          :width="inoxWidth"
          height="2.13"/>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inox2Left: 22,
      leaf2Left: 7,
    }
  },
  computed: {
    inox1X() {
      return this.showBg ?
        this.doorLeftWidth + this.doorWidth - this.inox2Left :
        this.doorLeftWidth + this.doorWidth - this.leaf2Left
    },
    inox1Y() {
      return this.doorTopHeight + this.doorHeight * .3
    },
    inox1Y2() {
      return this.doorTopHeight + this.doorHeight * .3 + 2
    },
    inox1Y3() {
      return this.doorTopHeight + this.doorHeight * .4 + 2
    },
    inox1Y4() {
      return this.doorTopHeight + this.doorHeight * .4
    },
    inoxX() {
      return this.showBg ?
        this.doorLeftWidth + this.inox2Left :
        this.doorLeftWidth + this.leaf2Left
    },
    inox2Y() {
      return this.doorTopHeight + this.doorHeight * .2
    },
    inox4Y() {
      return this.doorTopHeight + this.doorHeight * .7
    },
    inox4Y2() {
      return this.doorTopHeight + this.doorHeight * .6
    },
    inox4Y3() {
      return this.doorTopHeight + this.doorHeight * .6 + 2
    },
    inox4Y4() {
      return this.doorTopHeight + this.doorHeight * .7 + 2
    },
    inox5Y() {
      return this.doorTopHeight + this.doorHeight * .8
    },
    inox3Y() {
      return this.doorTopHeight + this.doorHeight * .5
    },
    inoxWidth() {
      return this.showBg ?
        this.doorWidth - this.inox2Left * 2 :
        this.doorWidth - this.leaf2Left * 2
    },
  }
}
</script>
